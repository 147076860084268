import React from 'react';
import LocalLoader from './LocalLoader';

const GlobalLoader = () => {
	return (
		<div className='globalLoader'>
			<LocalLoader/>
		</div>
	)
}

export default GlobalLoader;